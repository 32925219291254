import { render, staticRenderFns } from "./footprintdetail.component.html?vue&type=template&id=23b5a50b&scoped=true&"
import script from "./footprintdetail.component.js?vue&type=script&lang=js&"
export * from "./footprintdetail.component.js?vue&type=script&lang=js&"
import style0 from "./footprintdetail.component.scss?vue&type=style&index=0&id=23b5a50b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b5a50b",
  null
  
)

export default component.exports