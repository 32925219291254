import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Toolbar from '@/components/toolbar/toolbar';


export default {
    name: "FootprintDetail",
    data() {
        return {
            form: {
                image_banner: [],
                footer_image_1: '',
                footer_image_2: '',
                footer_image_3: '',
                footer_image_4: '',
                footer_image_5: '',
                footer_image_6: '',
                footer_image_7: '',
            },
            baseFront: this.$baseFront
        }
    },
    methods: {
        openLink(url) {
            console.log(url);
            window.open(url,'_blank')
        },
        async fetchSearch(id) {
            await this.$axios.get(`/api/show/footprint_detail/${id}`)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.form = response.data.data[0];
                        // JSON convert string array to array object
                        this.form.image_banner = this.form.parameter_image_banner == "" ? [] : JSON.parse(this.form.parameter_image_banner)
                        this.form.list_detail = this.form.list_detail == "" ? [] : JSON.parse(this.form.list_detail)
                    }
                })
            console.log(this.form);
        },
    },
    async mounted () {
        window.scrollTo(0, 0);
        await this.fetchSearch(this.$route.params.id)
        document.title = "AGC - Footprint";
        AOS.init();
    },
    components: {
        Footer,Header,Breadcrumb, Toolbar
    }
};